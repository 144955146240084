<template>
  <div class="service-setting">
    <PageTitleExtra
      :title="$t('serviceSetting.title')"
      :tooltip="$t('serviceSetting.tooltip')"
      cyBtn1="new-service-btn"
      :btn="$t('common.button.create.text')"
      @btnClick="$router.push({
        name: 'CreateReservationService'
      })"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_name"
        clearable
        :placeholder="$t('serviceSetting.search.service.placeholder')"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="serviceList" :empty-text="$t('common.table.empty.text')" testName="預約服務項目">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" :label="$t('serviceSetting.table.image.title')" align="center" width="150">
          <template slot-scope="scope">
            <img style="margin: auto;" width="90" :src="imgSource(scope.row.Image)" alt="">
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" :label="$t('serviceSetting.table.name.title')" width="150" align="center" />
        <BaseElTableColumn v-if="!isOverseasShop" prop="isPublic" :label="$t('serviceSetting.table.isPublic.title')" width="120" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? $t('common.yes.text') : $t('common.no.text') }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="usePubApt" prop="isPubApt" :label="$t('serviceSetting.table.isPubApt.title')" width="120" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPubApt)">{{ (scope.row.isPubApt)? $t('common.yes.text') : $t('common.no.text') }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="price" :label="$t('serviceSetting.table.price.title')" width="120" align="center">
          <template slot-scope="scope">
            {{ displayCurrencySymbol }} {{ scope.row.price }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="(chargeType === 'checkout' && useFeatures.forceCanHidePrice) || chargeType !== 'checkout'" prop="showPrice" :label="$t('serviceSetting.table.showPrice.title')" width="120" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showPrice || scope.row.showPrice === '0')? '-' : scope.row.showPrice }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="bookingTime" :label="$t('serviceSetting.table.bookingTime.title')" width="150" align="center" />
        <BaseElTableColumn prop="showTime" :label="$t('serviceSetting.table.showTime.title')" width="150" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showTime || scope.row.showTime === 0)? '-' : scope.row.showTime }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn class="test" prop="description" :label="$t('serviceSetting.table.description.title')" align="center" show-overflow-tooltip />
        <BaseElTableColumn prop="order" :label="$t('serviceSetting.table.order.title')" align="center" />
        <BaseElTableColumn prop="name" :label="$t('common.table.action.text')" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :hideCopy="false"
              :testName="scope.row?.name"
              @edit="$router.push({
                name: 'CreateReservationService',
                params: {
                  serviceId: scope.row.id
                }
              })"
              @delete="deleteDialog = true, selectRow=scope.row"
              @copy="$router.push({
                name: 'CreateReservationService',
                params: {
                  serviceId: scope.row.id,
                },
                query: { copy: true },
              })"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceCount"
        @pageChange="refresh(false)"
      />
    </section>

    <image-cropper v-if="uploadDialog" :image="formData.img" @uploaded="getImage" @close="uploadDialog = false" />
    <DeleteDialog
      v-if="deleteDialog"
      :title="$t('common.dialog.delete.title')"
      :content="$t('common.dialog.delete.content')"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteService(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceCategorySelect from '@/components/Select/ServiceCategorySelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { CreateService, GetService, GetServiceCount, DeleteService, UpdateService, FindService } from '@/api/service'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'

// Utils
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'
import { computed, onBeforeMount } from 'vue'
import { usePermissions } from '@/use/permissions'
import { useReservation } from '@/use/useReservation'
import { get } from 'lodash'
import store from '@/store'
import { useCurrency } from '@/use/useCurrency'

// // import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceSetting',
  components: {
    // UploadButton,
    ImageCropper,
    DeleteDialog,
    // ServiceCategorySelect,
    // ServiceUnitSelect,
    // AttachServiceSelect,
    EmptyBlock,
  },
  setup () {
    const { checkAction } = usePermissions()
    const { getConfig, configData } = useReservation()
    const { displayCurrencySymbol } = useCurrency()

    const useFeatures = computed(() => {
      return {
        forceCanHidePrice: checkAction('admin.appointmentService.enableShowPrice'),
      }
    })
    const usePubApt = computed(() => checkAction('admin.pubAptConfig.page'))
    const isOverseasShop = computed(() => get(store.state, 'org.isOverseasShop'))

    const chargeType = computed(() => {
      return get(configData, 'reservation.chargeType')
    })

    onBeforeMount(async () => {
      await getConfig('reservation')
    })
    return {
      useFeatures,
      chargeType,
      usePubApt,
      isOverseasShop,
      displayCurrencySymbol,
    }
  },

  data: () => ({
    nameSearch: '',
    loading: false,
    showDialog: false,
    deleteDialog: false,
    uploadDialog: false,
    dialogType: '',

    selectRow: null,
    selectService: null,

    serviceList: [],
    serviceCount: 0,

    avatarChanged: false,
    // imgSize: 90,
    imgSize: 90,

    formData: {
      img: null,
      avatar: null,
      name: '',
      description: '',
      hidePrice: false,
      hideTime: false,
      price: '',
      showPrice: '',
      showTime: '',
      bookingTime: '',
      order: 100,
      isPublic: true,
      attachService: [],
      serviceCategorys: [],
      serviceUnits: [],
    },

    formRules: {
      name: noEmptyRules(),
      description: noEmptyRules(),
      price: [isDigitRules(), noEmptyRules(), rangeRules()],
      // showPrice: [isDigitRules(false)],
      showTime: [isDigitRules(false), rangeRules()],
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
    },

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
    ]),

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增服務項目',
          update: '更新服務項目',
        },
      )
    },

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    categoryList () {
      const list = []
      this.formData.serviceCategorys.forEach(item => {
        list.push(item.id)
      })
      return list
    },

    serviceUnitList () {
      const list = []
      this.formData.serviceUnits.forEach(item => {
        list.push(item.id)
      })
      return list
    },
  },
  async mounted () {
    await this.refresh()
  },
  async activated () {
    await this.refresh()
  },
  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      //  if(!val) type = 'danger'
      return type
    },
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },
    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },
    async refresh (search = false) {
      if (search) this.tableOptions.page = 1
      this.loading = true
      await this.getService()
      await this.getServiceCount()
      this.loading = false
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createService()
      if (type === 'update') this.updateService()
      this.showDialog = false
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.avatar = row.Image
      this.formData.description = row.description
      this.formData.price = row.price

      if (row.showPrice && row.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = row.showPrice
      }
      if (row.showTime && row.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = row.showTime
      }
      this.formData.bookingTime = row.bookingTime
      this.formData.order = row.order
      this.formData.isPublic = row.isPublic
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        avatar: null,
        name: '',
        description: '',
        hidePrice: false,
        hideTime: false,
        price: '',
        showPrice: '',
        showTime: '',
        bookingTime: '',
        order: 100,
        isPublic: true,
        attachService: [],
        serviceCategorys: [],
        serviceUnits: [],
      }
    },

    //= > 創建服務
    async createService () {
      const form = this.formData
      if (!form.hidePrice) form.showPrice = '0'
      if (!form.hideTime) form.showTime = '0'
      try {
        await CreateService({
          shopId: this.shop,
          name: form.name,
          image: (!form.avatar) ? undefined : this.formData.avatar.id,
          description: form.description,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          isPublic: form.isPublic,
          appointmentCategories: this.categoryList,
          appointmentServiceAttaches: extractList('id', this.formData.attachService),
          appointmentUnits: this.serviceUnitList,
        })
        await this.refresh()
        this.$message.success('新增服務成功!')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
        console.log('錯誤', error)
      }
    },

    //= > 刪除服務
    async deleteService () {
      await DeleteService({
        shopId: this.selectRow.ShopId,
        id: this.selectRow.id,
      })

      await this.refresh()
      this.$message.success('已刪除服務!')
    },

    //= > 更新服務
    async updateService () {
      try {
        const form = this.formData
        if (!form.hidePrice) form.showPrice = '0'
        if (!form.hideTime) form.showTime = '0'
        await UpdateService({
          shopId: this.shop,
          id: this.selectService.id,
          name: form.name,
          image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
          description: form.description,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          isPublic: form.isPublic,
          appointmentCategories: this.categoryList,
          appointmentServiceAttaches: extractList('id', this.formData.attachService),
          appointmentUnits: this.serviceUnitList,
        })

        await this.refresh()
        this.$message.success('更新服務成功!')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得服務
    async getService () {
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetService({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceList = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 查找特定服務
    async findService () {
      try {
        const res = await FindService({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.selectService = res

        this.formData.attachService = this.selectService.AppointmentServiceAttaches.filter(item => !item.isRemove)
        this.formData.serviceUnits = this.selectService.AppointmentUnits.filter(item => !item.isRemove)
        this.formData.serviceCategorys = this.selectService.AppointmentCategories.filter(item => !item.isRemove)
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得服務總數
    async getServiceCount () {
      try {
        const res = await GetServiceCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCount = res.data.count
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">

.test {
  overflow: auto;
}
</style>
